// export const URL_BASE_SERVER = 'http://localhost:8000/'
// export const URL_BASE_CLIENT = 'http://localhost:3000/'

export const URL_BASE_SERVER = 'https://proposalapi.webidevi.in/'
export const URL_BASE_CLIENT = 'https://proposal.webidevi.in/'

export const URL_CLIENT_AUTH_CALLBACK = 'auth/google'
export const URL_LOGIN = URL_BASE_SERVER + 'api/v1/auth/google'
export const URL_LOGIN_CALLBACK = URL_BASE_SERVER + 'api/v1/auth/google/callback'
export const URL_USER = URL_BASE_SERVER + 'api/v1/user'
export const URL_LOGOUT = URL_BASE_SERVER + 'api/v1/auth/logout'
export const URL_USER_REGISTER = URL_BASE_SERVER + 'api/v1/register'
export const URL_USER_FORM_LOGIN = URL_BASE_SERVER + 'api/v1/login'

export const URL_STATE_LIST = URL_BASE_SERVER + 'api/v1/state'
export const URL_POLICY_LIST = URL_BASE_SERVER + 'api/v1/policy'
export const URL_LEGISLATION_TYPE_LIST = URL_BASE_SERVER + 'api/v1/legislation-type'
export const URL_PROPOSAL_CREATE = URL_BASE_SERVER + 'api/v1/proposal/create'
export const URL_PROPOSAL_TOP = URL_BASE_SERVER + 'api/v1/proposal/top'
export const URL_PROPOSAL_GET_BY_ID = URL_BASE_SERVER + 'api/v1/proposal/'
export const URL_PROPOSAL_CREATE_VARIENT = URL_BASE_SERVER + 'api/v1/proposal/varient/create/'
export const URL_PROPOSAL_ALL = URL_BASE_SERVER + 'api/v1/proposals'
export const URL_PROPOSAL_BY_PARENT_ID = URL_BASE_SERVER + 'api/v1/proposalChildren/'
export const URL_PROPOSAL_BY_CATEGORY_ID = URL_BASE_SERVER + 'api/v1/proposalByCategory/'

export const URL_COMMENT_POST = URL_BASE_SERVER + 'api/v1/comment'
export const URL_LIKE_POST = URL_BASE_SERVER + 'api/v1/like'
export const URL_DISLIKE_POST = URL_BASE_SERVER + 'api/v1/dislike'
export const URL_COMMENTS_BY_PORPOSAL_ID = URL_BASE_SERVER + 'api/v1/comments/'

export const URL_UPLOAD_FILE = URL_BASE_SERVER + 'api/v1/upload'

