import { Carousel, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import DraftBox from '../components/DraftBox'
import { Link } from 'react-router-dom'
import { URL_POLICY_LIST, URL_PROPOSAL_TOP } from '../env'


function PolicyCategoryLink(policy, index) {
  return <Col className="home-link" key={policy.id + policy.name + index}>
    <Link to={"/drafts?catID=" + policy.id} >{policy.name} Policy </Link>
  </Col>
}

function Home() {


  const [policies, setPolicies] = useState([])

  const [topProposals, setTopProposals] = useState([])

  useEffect(() => {

    fetch(URL_POLICY_LIST)
      .then(res => res.json())
      .then(res => {
        setPolicies(res.data);
      })
      .catch(err => console.error(err))

    fetch(URL_PROPOSAL_TOP)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setTopProposals(res.data);
      })
      .catch(err => console.error(err));
  }, [])



  return (
    <div>

      <Carousel controls={false}
        // style={{
        //   backgroundImage: "url('/images/header.jpg')",
        //   minHeight: '1200px',
        //   backgroundSize: 'cover'
        // }}
        >
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/header.jpg"
            alt="Policy Proposals Made Easy"
            style={{ 
              'filter': ' brightness(0.5)'
            }}
          />
          <Carousel.Caption className="text-top mb-5" >
            <div className="mb-5">
              <h1 style={{ fontSize: '3rem', opacity: 1 }} >Policy Proposals Made Easy</h1>
              <p style={{ opacity: 1 }}>Work with others to dynamically and quickly draft legislation</p>
              <Link to="/proposal-draft" style={{ opacity: 1 }}>
                <button className="btn btn-primary primary-background">Make a Draft</button>
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="row">
        {policies.map((policy, index) => {
          return PolicyCategoryLink(policy, index);
        })}
      </div>

      <div className="row">
        <Col className="text-center">
          <h1 className=" text-ul">Top Drafts</h1>
        </Col>
      </div>
      {/* <div className="row">
        <Col>
          <DraftBox></DraftBox>
        </Col>
        <Col>
          <DraftBox></DraftBox>
        </Col>
        <Col>
          <DraftBox></DraftBox>
        </Col>
      </div> */}

      <div className="row mt-3 mb-5">
        {topProposals.map((proposal, index) => {
          return <Col md={4} key={proposal.id + '-' + index}>
            <DraftBox proposal={proposal}></DraftBox>
          </Col>
        })}
        {/* <Col>
          <DraftBox></DraftBox>
        </Col>
        <Col>
          <DraftBox></DraftBox>
        </Col>
        <Col>
          <DraftBox></DraftBox>
        </Col> */}
      </div>
    </div>

  )
}

export default Home