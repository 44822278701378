import React, { useEffect, useState } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getAccessToken, logout } from '../utils'

function LogoutButton() {
    const _logout = (e) => {
        e.preventDefault();
        logout();
    }
    return <a href="#" className="nav-link" style={{ color: "#fff" }} onClick={_logout}> LOGOUT </a>
}

function TopBar() {

    const [isLoggedIn, setIsLoggedIn] = useState(false)


    useEffect(() => {
        let token = getAccessToken();
        if (token)
            setIsLoggedIn(true);
        else {
            setIsLoggedIn(false);
        }

    },[])

    return (
        <Navbar style={{ backgroundColor: '#5480ff', padding: "10px 10px" }} expand="lg">

            <Link to="/home" className="navbar-brand" style={{ color: "#fff" }}>ProposeNow</Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto me-5" style={{ color: "#fff" }}>
                    <Link to="/home" className="nav-link" style={{ color: "#fff" }}>HOME</Link>
                    <Link to="/drafts" replace={true} className="nav-link" style={{ color: "#fff" }}>DRAFTS</Link>
                    {/* <Link to="/about" className="nav-link" style={{ color: "#fff" }}>ABOUT</Link> */}

                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle"
                            href="/#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: "#fff" }}
                        >
                            ABOUT
                        </a>
                        <ul className="dropdown-menu me-5"
                            style={{ color: "#fff", backgroundColor: '#444444' }}
                            aria-labelledby="navbarDropdown">
                            <Link to="/about"
                                className="nav-link"
                                style={{ color: "#fff", paddingLeft: '1rem' }}>
                                Who We Are
                            </Link>
                            <li><hr className="dropdown-divider" /></li>
                            <Link to="/about2"
                                className="nav-link"
                                style={{ color: "#fff", paddingLeft: '1rem' }}>
                                Resources
                            </Link>
                        </ul>
                    </li>
                    {isLoggedIn ? <LogoutButton /> : <Link to="/login" className="nav-link" style={{ color: "#fff" }}>LOGIN</Link>}

                    {/* <Link to="/proposal"  className="nav-link" style={{ color: "#fff" }}>Proposal</Link> */}
                    {/* <Link to="/proposal-editor"  className="nav-link" style={{ color: "#fff" }}>Editor</Link> */}
                    {/* <Link to="/proposal-publish"  className="nav-link" style={{ color: "#fff" }}>Publish</Link> */}
                    {/* <Link to="/proposal-draft"  className="nav-link" style={{ color: "#fff" }}>Draft</Link> */}

                    {/* <Link to="/register"  className="nav-link" style={{ color: "#fff" }}>Register</Link> */}
                    {/* <Link to="/register2"  className="nav-link" style={{ color: "#fff" }}>Register2</Link> */}
                </Nav>
            </Navbar.Collapse>

        </Navbar>
    )
}

export default TopBar