import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { URL_PROPOSAL_CREATE_VARIENT } from '../env';
import { getAuthHeaders } from '../utils';

function Publish() {

    const location = useLocation();
    const navigate = useNavigate();

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [shortNote, setShortNote] = useState("")

    const publishVarient = () => {
        const url = URL_PROPOSAL_CREATE_VARIENT + location.state.parent.id;
        const headers = getAuthHeaders();
        fetch(url,{
            method: 'post',
            headers: headers,
            body: JSON.stringify({
                title, description, short_note: shortNote, content: location.state.content
            })
        })
        .then(res => res.json())
        .then(res => {
            if(res.success){
                navigate('/varients?parentID='+location.state.parent.id);
            }
        })
        .catch(err => console.log(err));
    }


    return (
        <div className='mt-4'>
            <Form.Group className="mb-3" controlId="formBasicTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={4}  value={description} onChange={e => setDescription(e.target.value)}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDifference">
                <Form.Label>Main Difference</Form.Label>
                <Form.Control type="text"  value={shortNote} onChange={e => setShortNote(e.target.value)}/>
            </Form.Group>
            <Row>
                <Col xs={12} md={12} className='mx-auto'>
                    <p className='text-center'>
                        <button onClick={publishVarient} className='btn btn-primary primary-background'>Publish Variant</button>
                        {/* <Link to='/drafts' type="button" className='btn btn-primary primary-background'>
                            Publish Varient Temp
                        </Link> */}
                    </p>
                </Col>
            </Row>

        </div>
    )
}

export default Publish