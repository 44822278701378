import React from 'react'
import { Col, Image, Row, Card, Container, Carousel } from 'react-bootstrap'

function About() {
  return (
    <Container>
      <Row className="mt-3">
        <Col xs={12} md={12} style={{ textAlign: "center" }}>

          {/* <Carousel controls={false}>
            <Carousel.Item>
            <Image thumbnail={false} height={400} src="/images/Abhi_1.jpeg"></Image>
            </Carousel.Item>
          </Carousel> */}
          <Row>
            <Col md={4} style={{ textAlign: "center" }}>
              <Image thumbnail={true} src="/images/Abhi_1.jpeg"></Image>
            </Col>
            <Col md={7} style={{ textAlign: "center" }}>
              <Image thumbnail={true}  src="/images/Abhi_2.jpeg"></Image>
            </Col>
          </Row>
          
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <p>
            My name is Abhinav Reddy, and I am the founder of ProposeNow. I’ve worked in
            politics for a few years and have always sought to improve civic participation through
            voter registration drives, public policy proposals, phone banking, and canvassing.
            ProposeNow has been created with a similar intention, specifically to make it easier for
            constituents to push their representative to make policy changes.
          </p>

          {/* <Card>
            <Card.Header> <h3 style={{textAlign: "center"}}>Mission Statement</h3></Card.Header>
            <Card.Body>
              <Card.Text>
                A democratic institution, such as the United States, relies on a well-informed base of voters who can vote for competent leaders and understand the country’s political system.
                Bridging the gap between constituents and representatives, especially with policy proposals, is an important issue in modern America.
                ProposeNow seeks to help bridge the gap and accelerate public policy initiatives.
              </Card.Text>
            </Card.Body>
          </Card> */}

          <h3 style={{ textAlign: "center" }}>Mission Statement</h3>

          <p>
            A democratic institution, such as the United States, relies on a well-informed base of voters who can vote for competent leaders and understand the country’s political system.
            Bridging the gap between constituents and representatives, especially with policy proposals, is an important issue in modern America.
            ProposeNow seeks to help bridge the gap and accelerate public policy initiatives.
          </p>


        </Col>
      </Row>
    </Container>
  )
}

export default About