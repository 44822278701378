import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router';
import { URL_STATE_LIST, URL_USER_REGISTER } from '../env';

function Register2() {

    const [states, setStates] = useState([])

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [state_id, setStateId] = useState("0");

    const navigate = useNavigate();

    useEffect(() => {
        fetch(URL_STATE_LIST)
            .then(res => res.json())
            .then(res => {
                setStates(res.data);
                console.log(res.data)
            })
            .catch(err => console.log(err));
    }, [])


    const submit = () => {
        let body = {
            email, name, password, state_id
        }

        fetch(URL_USER_REGISTER,{
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Accepts': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            navigate('/login');
        })
        .catch(err => console.log(err));
    }

    return (
        <Row className='mt-5'>
            <Col xs={12} md={4} className='mx-auto'>
                <Form>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type='email' value={email} onChange={(e)=>setEmail(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control type='text' value={name} onChange={(e)=>setName(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' value={password} onChange={(e)=>setPassword(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type='password' value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Form.Label>Select State</Form.Label>
                        <Form.Select type='text' value={state_id} onChange={ e => setStateId(e.target.value) }>
                        <option value="0">Select a State</option>
                            {states.map((item, index) => {
                                return <option value={item.id} key={item.id + item.name + index}>{item.name}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                </Form>
                <Row className='mt-5'>
                    <Col>
                        <p className='text-center'>
                            <button className='btn btn-primary px-4 primary-background' onClick={submit}>Create account</button>
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Register2