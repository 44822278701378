import React, { useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import CommentBox from '../components/CommentBox';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function ProposalEditor() {

  const editorRef = useRef(null);

  const location = useLocation();

  const navigate = useNavigate();

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const finalizeVarientCallback = () => {
    navigate('/proposal-publish', {
      state : {
        content: editorRef.current.getContent(),
        parent: location.state.parent
      }
    })
  }

  return (
    <Row className="mt-3">
      {/* <Col xs={12} md={2}>
        <h4>Draft Name</h4>
        <p>Author</p>
        <p>xxxx</p>
        <button className="btn btn-primary primary-background b-block"
          style={{width: '100%'}}
        > Make a Variant</button>
        <button className="btn btn-primary primary-background b-block mt-2"
          style={{width: '100%'}}
        > View Variants</button>

        <CommentBox className="mt-3">

        </CommentBox>
      </Col> */}

      <Col xs={12} md={12}>
      <Editor
        apiKey='6v1re7t5qd216w20qdzkm4j2n7tmx34dd1lwgzcmb5xob8t4'
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={location.state.parent.content}
        init={{
          height: window.innerHeight * 0.8,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
      <Row className='mt-3'>
        <Col md={12}  className="mx-auto">
          <p className='text-center'>
            <button className='btn btn-primary primary-background' onClick={finalizeVarientCallback}>Finalize Variant</button>
            {/* <Link to='/proposal-publish' className='btn btn-primary primary-background'>Finalize Varient temp</Link> */}
          </p>
        </Col>
      </Row>
      
      </Col>
    </Row>
  )
}

export default ProposalEditor