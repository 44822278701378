import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useLocation, useNavigate } from 'react-router';

import { URL_PROPOSAL_ALL, URL_PROPOSAL_BY_CATEGORY_ID, URL_PROPOSAL_BY_PARENT_ID } from '../env';
import useQuery from '../hooks/useQuery';


const columns = [
    {
        name: 'TITLE',
        selector: row => row.title,
        sortable: true,
    },
    {
        name: 'STATE',
        selector: row => row.state.name,
        sortable: true,
    },
    {
        name: 'CATEGORY',
        selector: row => row.category.name,
        sortable: true,
    },
    {
        name: 'NOTES',
        selector: row => row.description,
        sortable: true,
    },
];


const customStyles = {
    rows: {
        style: {
            borderCollapse: 'collapse'
        },
    },
    headCells: {
        style: {
            textAlign: 'center',
            backgroundColor: "#444444",
            color: '#ffffff',
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: '1px solid black'
        },
    },
    cells: {
        style: {
            textAlign: 'center',
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
            borderBottom: '1px solid black'
        },

    },
};
const onRowClicked = (navigate, row, event) => {
    navigate('/proposal?proposalID=' + row.id, { state: { id: row.id } })
}



function ProposalTable() {

    const navigate = useNavigate();
    const query = useQuery();
    const [loaded, setLoaded] = useState(false)

    const [drafts, setDrafts] = useState([])

    useEffect(() => {
        // if(!loaded)
        // {
        const catID = query.get("catID");
        const parentID = query.get("parentID");
        let fetchPromise = null;
        if (!catID && !parentID) {
            fetchPromise = fetch(URL_PROPOSAL_ALL);
        }
        else if (parentID) {
            fetchPromise = fetch(URL_PROPOSAL_BY_PARENT_ID + parentID);
        }
        else if (catID) {
            fetchPromise = fetch(URL_PROPOSAL_BY_CATEGORY_ID + catID);
        }
        else {
            console.log(!catID, !parentID)
        }
        fetchPromise.then(res => res.json())
            .then(res => {
                console.log(res)
                console.log(!catID, !parentID)
                setDrafts(res.data);
                setLoaded(true);
            })
            .catch(err => console.error(err));
        // setLoaded(true)
        // }

        // return () => setLoaded(true);

    }, [])


    if (loaded)
        return (
            <DataTable
                columns={columns}
                data={drafts}
                customStyles={customStyles}
                style={{ borderCollapse: 'collapse' }}
                onRowClicked={(r, e) => onRowClicked(navigate, r, e)}
            />
        )
    else
        // return <div className="container mt-5"><p className="text-center">Loading...</p></div>
        return <div className="container">
            <div class="center">
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
            </div>
        </div>
}

export default ProposalTable