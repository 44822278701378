import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'
import { URL_STATE_LIST } from '../env'

function Register() {

    const [states, setStates] = useState([])

    useEffect(() => {
      fetch(URL_STATE_LIST)
      .then(res => res.json())
      .then(state_list => {
        setStates(state_list);
        console.log(state_list)
      })
      .catch(err => console.log(err));
    
      return () => {
        
      }
    }, [])
    

    return (
        <Row className='mt-5'>
            <Col xs={12} md={4} className='mx-auto'>
                <Form>
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control type='text'></Form.Control>
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Label>Select State</Form.Label>
                        <Form.Select type='text'>
                            {states.map((item, index) => {
                                return <option value="{item.id}" key={item.id+item.name+index}>{item.name}</option>
                            } ) }
                        </Form.Select>
                    </Form.Group>
                </Form>
                <Row className='mt-5'>
                    <Col>
                        <p className='text-center'>
                            <button className='btn btn-primary px-4 primary-background'>Create account</button>
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Register