import React, { useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import CommentBox from '../components/CommentBox';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAuthHeaders } from '../utils';
import { URL_PROPOSAL_CREATE } from '../env';



function ProposalEditor() {

  const editorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [proposalBody, setProposalBody] = useState({
    ...location.state,
    content: ""
  })

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  function finalizeDraft()
  {
    let body = {
      policy_category_id: proposalBody.policy,
      state_id: proposalBody.state,
      legislation_type_id: proposalBody.legislationType,
      title: proposalBody.title,
      description: proposalBody.description,
      image: proposalBody.imageName
    }
    body.content = editorRef.current.getContent();
    
    let headers = getAuthHeaders();

    fetch(URL_PROPOSAL_CREATE, {
      method: 'post',
      headers: headers,
      body : JSON.stringify(body)
    })
    .then(res => res.json())
    .then(res => {
      console.log(res)
      navigate('/drafts');
    })
    .catch(err => console.error(err));

  }

  return (
    <Row className="mt-3">
      {console.log(location.state)}
      {/* <Col xs={12} md={2}>
        <h4>Draft Name</h4>
        <p>Author</p>
        <p>xxxx</p>
        <button className="btn btn-primary primary-background b-block"
          style={{width: '100%'}}
        > Make a Variant</button>
        <button className="btn btn-primary primary-background b-block mt-2"
          style={{width: '100%'}}
        > View Variants</button>

        <CommentBox className="mt-3">

        </CommentBox>
      </Col> */}

      <Col xs={12} md={12}>
      <Editor
        apiKey='6v1re7t5qd216w20qdzkm4j2n7tmx34dd1lwgzcmb5xob8t4'
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: window.innerHeight * 0.8,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
      <Row className='mt-3'>
        <Col md={12}  className="mx-auto">
          <p className='text-center'>
            {/* <button onClick={finalizeDraft}>Test</button> */}
            <button className='btn btn-primary primary-background' onClick={finalizeDraft}>Finalize Draft</button>
            {/* <Link to='/drafts' className='btn btn-primary primary-background'>Finalize Draft</Link> */}
          </p>
        </Col>
      </Row>
      
      </Col>
    </Row>
  )
}

export default ProposalEditor