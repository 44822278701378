import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { URL_BASE_CLIENT, URL_LOGIN, URL_USER_FORM_LOGIN } from '../env'
import { getAccessToken, saveAccessToken } from '../utils'

function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")


  function submit() {
    let body = {
      email, password
    }

    console.log(body);
    fetch(URL_USER_FORM_LOGIN, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Accepts': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        saveAccessToken(res.access_token);
        console.log(res)
        setTimeout(() => {window.location = URL_BASE_CLIENT}, 1000)
      })
      .catch(err => console.error(err));
  }


  return (
    <Row className='mt-5'>
      <Col xs={12} md={4} className='mx-auto'>
        {/* <Form> */}
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control type='email' value={email} onChange={e => setEmail(e.target.value)}></Form.Control>
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' value={password} onChange={e => setPassword(e.target.value)}></Form.Control>
        </Form.Group>
        <Row>
          <Col xs={6} md={6} lg={5} className='mx-auto text-center'>
            <button className='btn btn-primary mt-5 px-5 primary-background' onClick={submit}>Login</button>
          </Col>
        </Row>

        <Row className='mt-5 mb-5'>
          <Col>
            <p className='text-center'>Login with or Sign Up with</p>
            <p className='text-center'>
              <a href={URL_LOGIN} className='btn btn-outline-dark px-5'>Google</a>
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className='text-center'> Don't Have an Account?
              <Link to='/register2'> Sign Up</Link>

            </p>
          </Col>
        </Row>

        {/* </Form> */}
      </Col>
    </Row>
  )
}

export default Login