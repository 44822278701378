import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "./App.css"
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopBar from "./components/TopBar";
import About from "./pages/About";
import About2 from "./pages/About2";
import Home from "./pages/Home";
import Category from "./pages/Category";
import Login from "./pages/Login";
import ProposalEditor from "./pages/ProposalEditor";
import VarientEditor from "./pages/VarientEditor";
import Publish from "./pages/Publish";
import Draft from "./pages/Draft";
import Register from "./pages/Register";
import Register2 from "./pages/Register2";
import ProposalViewer from "./pages/ProposalViewer";
import { useEffect } from 'react';
import { useNavigate } from "react-router";
import { URL_BASE_CLIENT, URL_LOGIN_CALLBACK } from "./env";
import { getAccessToken, saveAccessToken, saveUser } from "./utils";



const LoginGoogle = () => {

  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {

    if (!getAccessToken()) {

      fetch(`${URL_LOGIN_CALLBACK}${window.location.search}`, { headers: new Headers({ accept: 'application/json' }) })
        .then((response) => {
          let json = response.json()
          if (response.ok) {
            // console.log(response.headers.get('Access-Token'))
            // console.log("response", json)
            let t = response.headers.get('Access-Token')
            setToken(t);
            saveAccessToken(t);
            setLoaded(true)
          }
          return json;
          // throw new Error('Something went wrong!');
        })
        .then((data) => {
          if (loaded && data) {
            let email = data.email;
            let name = data.name;
            // saveAccessToken(token);
            saveUser({ email, name });
            console.log(data)
          }
          console.log(data, token);
          setTimeout(() => {window.location = URL_BASE_CLIENT;}, 1000) 
        })
        .catch((error) => {
          console.error(error);
        });
    }

  })

  return <div></div>;
}


function App() {

  const [firstTry, setFirstTry] = useState(true)

  

  return (
    <BrowserRouter>
      <TopBar></TopBar>
      <div className="container-fluid">
        <Routes >
          <Route path="/auth/google" element={<LoginGoogle />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/drafts" element={<Category key={Date.now()} />}/>
          <Route path="/varients" element={<Category />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<About />} />
          <Route path="/about2" element={<About2 />} />
          <Route path="/proposal-editor" element={<ProposalEditor />} />
          <Route path="/varient-editor" element={<VarientEditor />} />
          <Route path="/proposal" element={<ProposalViewer />} />
          <Route path="/proposal-publish" element={<Publish />} />
          <Route path="/proposal-draft" element={<Draft />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register2" element={<Register2 />} />

          {/* <Route path="invoices" element={<Invoices />} /> */}
        </Routes>
      </div>
    </BrowserRouter>

  );
}

export default App;
