import React from 'react'
import ProposalTable from '../components/ProposalTable'

function Category() {
  return (
    <div>

      <ProposalTable></ProposalTable>
    </div>
  )
}

export default Category