import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CommentBox from '../components/CommentBox';
import { URL_PROPOSAL_GET_BY_ID } from '../env';
import useQuery from '../hooks/useQuery';

function ProposalViewer() {


  const query = useQuery();
  const [draft, setDraft] = useState({
    id: '',
    title:"",
    content:"",
    user:{name:""} 
  })

  useEffect(() => {
    const draftId = query.get('proposalID');
    const url = URL_PROPOSAL_GET_BY_ID + draftId;
    fetch(url)
    .then(res => res.json())
    .then(res => {
      setDraft(res.data)
      console.log(res)
    })
    .catch(err => console.error(err));
  }, [])
  

  return (
    <Row className="mt-3">
      <Col xs={12} md={2}>
        <h4> {draft.title} </h4>
        <p>{draft.user.name}</p>
        <div className='row mb-3'>
          <Col xs={10}>xxxx</Col>
          <Col xs={2}> <img src="/images/notes.png" alt='' className='img-thumbnail' /> </Col>
        </div>
        <Link to='/varient-editor' state={{parent: draft}} className="btn btn-primary primary-background b-block"
          style={{width: '100%'}}
        > Make a Variant</Link>  
        <Link to={'/varients?parentID=' + draft.id}  className="btn btn-primary primary-background b-block mt-2"
          style={{width: '100%'}}
        > View Variants</Link>
        {draft.id ? <CommentBox proposal_id={draft.id} className="mt-3" />: ''}
        
      </Col>

      <Col xs={12} md={10}>
      <div style={{height: '80vh', border: '2px solid silver'}}  dangerouslySetInnerHTML={{ __html: (draft.content) }}>
      </div>
      {/* <Row className='mt-3'>
        <Col md={3}  className="mx-auto">
        <button className='btn btn-primary primary-background'>Finalize Varient</button>
        </Col>
      </Row> */}
      
      </Col>
    </Row>
  )
}

export default ProposalViewer