import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { URL_BASE_CLIENT, URL_BASE_SERVER } from '../env'

function formatedDate(dt) {
    const d = new Date(dt)
    const year = d.getFullYear() // 2019
    const date = d.getDate()
    // const months = {
    //     0: 'January',
    //     1: 'February',
    //     2: 'March',
    //     3: 'April',
    //     4: 'May',
    //     5: 'June',
    //     6: 'July',
    //     7: 'August',
    //     8: 'September',
    //     9: 'October',
    //     10: 'November',
    //     11: 'December'
    //   }
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    // console.log(monthName) 
    const monthName = months[d.getMonth()]
    const days = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ]

    // const dayName = days[d.getDay()]
    const formatted = `${monthName} ${date} ${year}`
    return formatted
}
// `url(${URL_BASE_SERVER}uploads/${proposal.image})`
function DraftBox({ proposal }) {
    return (
        <Card className="mx-4 mt-3" style={
            {
                height: '15rem',
            }}>
            <Card.Body style={{
                color: '#ffffff',
                overflow: 'hidden'
            }}>
                <Card.Img src={`${URL_BASE_SERVER}uploads/${proposal.image}`} style={{
                    // height:'15rem',
                    filter: 'brightness(0.5)'
                }} />
                <Card.ImgOverlay className="m-3">

                    <Row>
                        <Col >
                            <span className="mb-5 draft-cat">{proposal.category.name}</span>
                            <br />
                            <span className="fs-3 mt-5" style={{ color: "white" }}>{proposal.title}</span>
                            <br />
                        </Col>
                        {/* <Col xs={6} md={6}>
                            <img 
                            className='img-thumbnail' 
                            src={`${URL_BASE_SERVER}uploads/${proposal.image}`} alt="" />
                        </Col> */}
                    </Row>
                    <Row>
                        <Col>
                            <p>{proposal.description.substr(0, 40)}...</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="text-left">
                                <span className="circle">&nbsp;</span>
                                <span>{proposal.user.name}</span>
                            </span>
                        </Col>
                        <Col>
                            <p className="text-end">{formatedDate(proposal.created_at)}</p>
                        </Col>

                    </Row>

                </Card.ImgOverlay>
            </Card.Body>
        </Card>
    )
}

export default DraftBox