import { URL_BASE_CLIENT, URL_LOGOUT, URL_USER } from "./env";

const ACCESS_TOKEN_KEY = 'access_token';
const USER_KEY = 'user';

export const saveUser = (user) => {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export const loadUser = () => {
    let user = localStorage.getItem(USER_KEY)
    if (user)
        user = JSON.parse(user);
    else return null;
    return user;
}

export const saveAccessToken = (token) => {
    if(token){
        console.log("saving token", token);
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }
}

export const getAccessToken = () => {
    let token = localStorage.getItem(ACCESS_TOKEN_KEY);
    // validateLogin();
    if (token)
        return token;
    return null;
}

export const getAuthHeaders = () => {
    let token = getAccessToken();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    return myHeaders;
}

export const logout = () => {
    console.log("logging out");
    let token = getAccessToken();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    fetch(URL_LOGOUT,{
        method: 'post',
        headers: myHeaders,
        redirect: 'follow'
    })
    .then(res => {
        // if(res.ok){
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(USER_KEY);
            window.location = URL_BASE_CLIENT;
        // }
    })
    .catch(e => console.error(e));

    
}

export const validateLogin = () => {

    let token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token)
        logout();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(URL_USER, requestOptions)
    .then(response => {
        if(response.status !== 200)
        {
            logout();
        }
        return response.json()
    })
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}