import React from 'react'

function About2() {
    return (
        <div className="container mt-5">
            <p>
                There are four different forms of legislation. Two of them (bills and joint resolutions) are used for making law, while the other two (simple resolutions and concurrent resolutions) are used
                for matters of congressional administration and to express nonbinding policy views. Joint resolutions are also used to propose constitutional amendments for ratification by the States.
            </p>
            <p>
                For a bill or joint resolution to become law, section 7 of article I of the Constitution requires that it pass both houses of Congress and be presented to the President. It will become law if the
                President signs it, if the President vetoes it and Congress overrides the veto by a two-thirds vote, or if ten days pass without any action by the President (while Congress is in session).
                Simple resolutions and concurrent resolutions are not presented to the President because they do not become law. Joint resolutions proposing constitutional amendments are governed
                instead under article V of the Constitution, which does not require presentment to the President.


            </p>
            <p>
                There is no legal difference between a law that originated as a bill and a law that originated as a joint resolution. Congress chooses between bills and joint resolutions using conventions
                that have developed over time for the subject matter involved. Bills are more common than joint resolutions, but a prominent example of a joint resolution is a resolution to make continuing
                appropriations beyond the end of a fiscal year when the regular appropriations bills for the next year have not been completed (a “continuing resolution” or “CR”).


            </p>

            <p>
                One other difference between bills and joint resolutions is stylistic. When a bill passes one house of Congress, its designation changes from “A Bill” to “An Act”, even though it has not yet
                become law. A “Joint Resolution” keeps the same designation even after passage by both houses and enactment.


            </p>

            <p>
                The section is the basic unit of organization of a bill, and thus of an enacted statute. Section 104 of title 1, United States Code, provides that a section “shall contain, as nearly as may be, a
                single proposition of enactment”. The terminology for referring to units within a section has become highly standardized and should be carefully followed to avoid confusion. The
                breakdown of a section is as follows:


            </p>

            <p>
                <b> SECTION 1.</b> (“SECTION” for 1st section and “ §” for subsequent sections, followed by Arabic numeral)
            </p>
            <p>
            <b>(a) (Subsection)</b>(lower-case letter) <br />
            <b>(1) (Paragraph)</b>(Arabic numeral) <br />
            <b>(A) (Subparagraph)</b>(upper-case letter) <br />
            <b>(i) (Clause)</b>(lower-case Roman numeral) <br />
            <b>(I) (Subclause)</b>(upper-case Roman numeral) <br />

            </p>
            

            <p>In larger bills, sections may be organized into higher-level units. The terminology for such units varies from bill to bill, but the following terms are often used (from the highest level to the
                level immediately above a section): title I, subtitle A, chapter 1, subchapter A, part I, subpart 1.</p>
            <p>
                General rule: State the main message. <br />

                Exceptions: Describe the persons or things to which the main message does not apply.

                <br />Special rules: Describe the persons or things to which the main message applies in a different way or for which there is a different message.

                <br />   Transitional rules.

                <br />   Other provisions.

                <br />   Definitions

                <br />   Effective date

                <br />   "Authorization of appropriations" provisions
            </p>
        </div>
    )
}

export default About2