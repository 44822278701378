import React, { useEffect, useState } from 'react'
import { Col, Form, FormSelect, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { URL_LEGISLATION_TYPE_LIST, URL_POLICY_LIST, URL_STATE_LIST, URL_UPLOAD_FILE } from '../env'
import { getAccessToken, getAuthHeaders, validateLogin } from '../utils'






function Draft() {

    const [states, setStates] = useState([])
    const [state, setState] = useState("")
    const [policies, setPolicies] = useState([])
    const [policy, setPolicy] = useState("")
    const [legislationTypes, setLegislationTypes] = useState([])
    const [legislationType, setLegislationType] = useState("")
    const [imageName, setImageName] = useState("");
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    const navigate = useNavigate();

    useEffect(() => {

        // validateLogin();

        if(!getAccessToken()){
            navigate('/login');
        }

        fetch(URL_STATE_LIST)
            .then(res => res.json())
            .then(res => setStates(res.data))
            .catch(err => console.error(err));

        fetch(URL_POLICY_LIST)
            .then(res => res.json())
            .then(res => setPolicies(res.data))
            .catch(err => console.error(err));

        fetch(URL_LEGISLATION_TYPE_LIST)
            .then(res => res.json())
            .then(res => {
                setLegislationTypes(res.data);
                console.log(res)
            })
            .catch(err => console.error(err));


    }, [])


    const uploadImage = (element) => {
        let files = element.files

        
        console.log(1, files[0], element.value);
        if( files && files.length === 1)
        {
            const headers = getAuthHeaders();
            headers.delete("Content-Type")

            var formdata = new FormData();
            formdata.append("image", files[0]);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: formdata,
                redirect: 'follow'
            };

            fetch(URL_UPLOAD_FILE, requestOptions)
            .then(response => response.json())
            .then(result => {
                setImageName(result.image);
            })
            .catch(error => console.log('error', error));
        }
    }

    const submitForm = () => {
        navigate('/proposal-editor', {state:{state, policy, legislationType, title, description, imageName}});
    }


    return (
        // <Form className='mt-5'>
        <div className="mt-5">
            <Form.Group className="mb-3" controlId="formBasicTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicImage">
                <Form.Label>Image</Form.Label>
                <Form.Control type="file" name="image" onChange={e => uploadImage(e.target)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={4}  value={description} onChange={e => setDescription(e.target.value)}  />
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Select Category</Form.Label>
                        <FormSelect value={policy} onChange={e => { setPolicy(e.target.value) }}>
                            <option value="">Select a policy</option>
                            {policies.map((policy, index) => {
                                return <option key={policy.id + policy.name + index} value={policy.id}>{policy.name}</option>
                            })}
                        </FormSelect>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Select State</Form.Label>
                        <FormSelect value={state} onChange={e => { setState(e.target.value) }}>
                            <option value="">Select a State</option>
                            {states.map((state, index) => {
                                return <option key={state.id + state.name + index} value={state.id}>{state.name}</option>
                            })}
                        </FormSelect>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Select Legislation Type</Form.Label>
                        <FormSelect value={legislationType} onChange={e => { setLegislationType(e.target.value) }}>
                            <option value="">Select Legislation Type</option>
                            {legislationTypes.map((legislation, index) => {
                                return <option key={legislation.id + legislation.name + index} value={legislation.id}>{legislation.name}</option>
                            })}
                        </FormSelect>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col xs={12} md={12} className='mx-auto'>
                    <p className='text-center'>
                    
                    <button className='btn btn-primary primary-background px-5' onClick={submitForm}>Start Drafting</button>
                    {/* <Link
                        to='/proposal-editor'
                        state={{state, policy, legislationType, title, description, imageName}}
                        className='btn btn-primary primary-background px-5'
                    >
                        Start Drafting2
                    </Link> */}
                    </p>

                </Col>
            </Row>
        </div>
        // </Form>
    )
}

export default Draft